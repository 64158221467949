<template lang="pug">
v-data-table(:items="forecast_computed" hide-default-footer :mobile-breakpoint="0" :headers="headers" disable-pagination)
	template(#item="{item: forecast_item}")
		tr
			td
				span.label.round(:class="forecast_item.rankClass") {{forecast_item.ranking}}
			td
				nuxt-link(:to="localePath({name: 'areas-area-forecast', params: { area: forecast_item.slug }})" :title="`Sneeuwhoogte ${forecast_item.name}`")
					img.flag(:src="`https://static.wintersport.nl/images/flags/svg/${forecast_item.countryCode}.svg`", :alt="forecast_item.countryCode")
					| {{forecast_item.name}}
			td.text-xs-right.text-md-left
				snowheight-bar(:max="forecast_computed[0].snowMountain"  :snowheight="forecast_item.snowMountain")
			td
				snowheight-bar(:max="forecast_computed[0].snowMountain" :snowheight="forecast_item.snowValley")
			td(v-if="!hideLifts")
				v-sheet.label.text-center.font-weight-bold( dark :color="forecast_item.elevatorsOpen > 0 ? 'success' : 'error'" ) {{forecast_item.elevatorsOpen}}/{{forecast_item.elevatorsTotal}}
</template>

<style lang="sass">
.label.round
	font-size: 80%
	border-radius: 50%
	display: block
	width: 24px
	height: 24px
	text-align: center
	line-height: 24px
</style>

<script>
import SnowheightBar from '@dengl/site/components/snowheight-bar.vue';
// rank classnames
const addRankClass = forecast => {
	forecast.rankClass =
		forecast.ranking - 1 > 2 ? ['black--text'] : ['white--text'];
	const rankColors = ['gold', 'silver', 'bronze'];
	forecast.rankClass.push(rankColors[forecast.ranking - 1] || 'grey lighten-3');
	return forecast;
};

export default {
	components: {
		SnowheightBar
	},
	props: {
		limit: {
			type: Number,
			default: -1
		},
		forecast: {
			type: Array,
			required: true,
			default() {
				return null;
			}
		},
		hideLifts: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		headers() {
			return [
				{ text: '', value: 'ranking', sortable: false },
				{
					text: this.$t('snowfall_table.head.name'),
					value: 'destination.name'
				},
				{
					text: this.$t('snowfall_table.head.snow_mountain'),
					value: 'snowMountain'
				},
				{
					text: this.$t('snowfall_table.head.snow_valley'),
					value: 'snowValley'
				},
				this.hideLifts
					? null
					: {
							text: this.$t('snowfall_table.head.openlifts'),
							value: 'elevatorsOpen'
					  }
			].filter(h => !!h);
		},
		forecast_computed() {
			if (!this.forecast) return [];

			// FIXFIXFIX
			const forecastsMapped = this.forecast.map((f, index) => {
				const { elevatorsOpen, elevatorsTotal } = f.services.reduce(
					(acc, service) => {
						acc.elevatorsOpen += service.elevatorsOpen;
						acc.elevatorsTotal += service.elevatorsTotal;

						return acc;
					},
					{ elevatorsOpen: 0, elevatorsTotal: 0 }
				);

				return {
					...f,
					snowMountain: f.top.forecast.forecast24[5] ? f.top.forecast.forecast24[5].totalSnow : 0,
					snowValley: f.low.forecast.forecast24[5] ? f.low.forecast.forecast24[5].totalSnow : 0,
					ranking: index + 1,
					elevatorsOpen: (!this.hideLifts && elevatorsOpen) || 0,
					elevatorsTotal: (!this.hideLifts && elevatorsTotal) || 0
				};
			});
			return forecastsMapped.map(addRankClass);
		}
	}
};
</script>
